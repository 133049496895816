<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户回收站页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="crm-content">
  	<!--引入顶部导航栏组件-->
    <commonHeader
      :my-title="myTitle"
      @searchInputClick="searchInputClick"
      @returnCrm="returnCrm">
    </commonHeader>
    <!--操作-->
    <div class="crm-content-opr">
      <div class="crm-content-opr-tip">
        <span>操作：</span>
        <span class="opr-btn" @click="restore">还原</span>
      </div>
    </div>
    <!--表格-->
    <div class="w100">
    	<div class="w100">
    		<table width="100%" cellpadding="0" cellspacing="0" border="0" class="crm-client-infoli">
    			 <thead>
        <tr>
        	<th height="30" class="th" style="width: 20px; padding: 10px 0px;"></th>
          <th height="30" class="th" style="width: 50px;">
            <el-checkbox  @change="selectAll($event)" class="selectButton"></el-checkbox>
          </th>
          <th height="30" class="th">
            <div class="firm-info">企业信息</div>
          </th>
          <th height="30" class="th" width="150">
            <div>录入时间</div>
          </th>
          <th height="30" class="th" width="150">
            <div>最后联系时间</div>
          </th>
          <th height="30" class="th" width="200">
            <div>客户分类</div>
          </th>
          <th height="30" class="th" width="200">
            <div>客户状态</div>
          </th>
          <th width="18" class="th" v-show='infoLists.length>9' style="padding: 8px 0;"></th>
        </tr>
        </thead>
    		</table>
    	</div>
    	<div class="w100 newTableStyle" style="top: 123px;">
    		<table width="100%" cellpadding="0" cellspacing="0" border="0" class="crm-client-infoli"
      	v-loading="autographLoading" element-loading-text="拼命加载中">
        <tbody>
        <tr v-for="(th,index) in infoLists">
        	<td class="td" height="50" style="width: 20px; padding: 10px 0px;"></td>
          <td class="td" height="50" width="50">
            <el-checkbox v-model="selectArr" :label="th.customer_id" :key="index"></el-checkbox>
          </td>
          <td class="td" height="50">
            <div  class="firm-info" >
              <img :src="th.img_urls" alt="用户头像" class="name-bg"/>
              <div class="info-p">
                <p class="infoName" @click="opens(th.customer_id,th.user_id)" :title="th.name">{{th.name}}</p>
                <br>
                <p class="email" :title="th.email">{{th.email}}</p>
              </div>
            </div>
          </td>
          <td class="td" height="50" width="150">{{th.enrol}}</td>
          <td class="td" height="50" width="150">{{th.date}}</td>
          <td class="td" height="50" width="200">{{th.classify_name}}</td>
          <td class="td" height="50" width="200">{{th.status_name}}</td>
        </tr>
        </tbody>
      </table>
      <div class="myNoData" v-show="infoLists.length == 0">
					<p><i class="icon iconfont icon-zanwushuju"></i></p>
					<span>暂无数据</span>
			</div>
     </div>
    </div>
    <!--分页-->
    <div class="newPagingStyle" v-if="infoLists.length>0">
    	 <el-pagination
        class="crmCCenter"
        :current-page.sync="currpage"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pagesize"
        layout="total,sizes, prev, pager, next, jumper"
        :page-count='tolpage'
        :total="tolRecord"
        @size-change='siezChange'
        @current-change='commonAjax()'
        >
      </el-pagination>
    </div>
    <!--点击企业信息名出现弹框-->
    <moreInfo v-if="test" @closeInfo="close" :customer_id="customer_id" :user_id="user_id"></moreInfo>
  </div>
</template>
<script>
  import moreInfo from "./CommonItem/crmMoreInfo.vue"//详细信息弹窗
  import commonHeader from "./CommonItem/searchBar.vue"//搜索框
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../vuex/mutations.js';
  import { Message } from 'element-ui';
  export default ({
    name:"crm-content",
    props:["isRecycle"],
    data(){
    return{
    	currpage:1,//当前页
      tolpage:1,//总页数
      tolRecord:1,//总条数
    	pagesize:10,//每页显示数量
    	cusKeyword:"",// 搜索内容
    	infoLists:[],//客户列表集合
    	selectArr:[],//选择的checkbox,客户id
      autographLoading:false,//加载框
    	country_flag : '', // 国旗
      test:false,
      showC:false,
      showG:false,
      
      myTitle:[
        {name:"回收站"}
      ],
      user_id:0,
    }
  },
  components:{
    moreInfo,
    commonHeader
  },
  computed:{
    ...mapState({
        userinfo: state => state.userinfo,
        customerclassifyall: state=> state.customerclassifyall,
        customerstatusall: state=> state.customerstatusall,
        userlistall: state=> state.userlistall,
        loadCusNumTag: state=> state.loadCusNumTag,
    })
  },
  mounted(){
  	//加载客户分类信息
  	if(this.customerclassifyall == ''){
  		this.getCustomerClassifyAll();
  	}
  	//加载客户状态
  	if(this.customerstatusall == ''){
  		this.getCustomerStatusAll();
  	}
  	//加载页面数据
  	this.commonAjax();
 	},
  methods:{
  	...mapMutations({
      getCustomerClassifyAll:'getCustomerClassifyAll',//加载客户分类
      getCustomerStatusAll:'getCustomerStatusAll',//加载客户状态
    }),
    //页数改变执行
    siezChange(size){
    	this.pagesize = size;
    	this.commonAjax();//加载页面数据
    },
    //接收公共搜索点击事件
    searchInputClick(searchInput){
    	this.cusKeyword = searchInput;
    	this.commonAjax();//加载页面数据
    },
    //加载客户列表
    commonAjax(){
      let _this = this;
      //_this.selectArr = [];//清空选择
      $.ajax({
        type:"post",
        url:backUrl+"cus_findValueCustomer.action",
        data:{
          token:token,
          pagesize : _this.pagesize,
          currpage : _this.currpage,
          condition:4,
          value:_this.cusKeyword
        },
        async : true,
				cache : false,
				dataType : "json",
				beforeSend : function() {
          _this.autographLoading=true;
        },
        complete : function() {
          _this.autographLoading=false;
        },
        success:function(data){
        	_this.tolpage = data.pageBean.totalPage; //总页数
        	_this.tolRecord = data.pageBean.totalRecord;//总条数
        	_this.infoLists = [];
          let list = data.crmcustomerlist;
          for(let i in list){
          	var imgurl = backUrl+"img/male_user.png";
          	if (list[i].img_url !== undefined && list[i].img_url !== null && list[i].img_url != 'null'  && list[i].img_url !== "") {
              if(list[i].img_url.indexOf("http://")!=-1 || list[i].img_url.indexOf("https://")!=-1){
									imgurl = list[i].img_url
								}else{
									imgurl = backUrl + list[i].img_url
								}
						}
          	var lastDate = "暂无活跃";
          	if(list[i].last_contact_date != null && list[i].last_contact_date != ''){
          		lastDate = list[i].last_contact_date.split(" ")[0];
          	}
            //所属客户分类
						var classify_name = "未分类";
						$.each(_this.customerclassifyall, function(s, row) {
							if (row.id == list[i].classify_id) 
							{
								classify_name = row.name;
								return false;
							}
						});
						//所属客户状态
						var status_name = "未分类";
						$.each(_this.customerstatusall, function(s, row) {
							if (row.id == list[i].customer_status) 
							{
								status_name = row.name;
								return false;
							}
						});
            _this.infoLists.push({
              "name":list[i].customer_name,
              "email":list[i].email,
              "enrol":list[i].enrol_time.split(' ')[0],
              "date":lastDate,
              "img_urls":imgurl,
              "classify_name":classify_name,
              "status_name":status_name,
              "customer_id":list[i].customer_id,
              "user_id":list[i].user_id
            });
          }
        }
      })
    },
		//点击企业的名称弹出框
    opens:function(cus_id,uid){
    	this.customer_id = cus_id;
    	this.user_id = uid;
      this.test = true;
    },
  	//关闭弹窗
    close:function(msg){
      this.test = msg;
    },
    restore:function(){
    	if(this.selectArr.length == 0){
    		this.$message({
	        showClose: true,
	        message: '请选择客户列表!',
	        type: 'warning',
	        duration: 2000
	      });
	      return;
    	}
    	this.$confirm('确定还原?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        cancelButtonClass:'MBcancelBtn'
      }).then(() => {
      	var cud_ids = '';//客户id
      	$.each(this.selectArr, function(i,row) {
      		cud_ids+=row+',';
      	});
      	cud_ids = cud_ids.substring(0,cud_ids.length-1);
      	var lef = this;
        $.ajax({
          type:'post',
          url:backUrl+'cus_updateMyCustomerStatus.action',
          data:{
            token:token,
            customerid:cud_ids
         	},
          success:function(msg){
          	if(msg == 1){
          		lef.$message({
				        showClose: true,
				        message: '还原成功！',
				        type: 'success',
				        duration: 2000
				      });
				      lef.selectArr = [];
	            lef.commonAjax();//重新加载回收站客户列表
	            //重新加载回收站客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
						  lef.$store.state.loadCusNumTag = 3;
          	}
          	else{
          		lef.$message({
				        showClose: true,
				        message: '还原失败！',
				        type: 'error',
				        duration: 2000
				      });
          	}
          },
          error:function(){
            lef.$message({
              showClose: true,
              message: '还原失败',
              type: 'error',
              duration: 2000
            });
         	}
        })
      }).catch(() => {});
    },
    selectAll(event){
        var _this = this;
        // if (!event.currentTarget.checked) {
        if (!event) {
          //反选
          this.selectArr = [];
        } else { //实现全选
          _this.selectArr = [];
          _this.infoLists.forEach(function(item, i) {
            _this.selectArr.push(item.customer_id);
          });
        }
    },
    returnCrm(){
      this.$emit('returnCrm');
    }
  }
})
</script>
<style src="../../css/crm/crm_content.css"></style>
<style scoped="">
.newTableStyle{
	/* position: absolute;
	top: 82px;
	bottom: 50px;
	overflow: auto; */
}
</style>
